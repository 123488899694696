import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import errorImage from '../../assets/images/error.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const NotFound = () => {
    return (
        <div className='not-found'>
            <div className='container inner-found'>
                <div className='right-error'>
                    <h2>ERROR 404</h2>
                    <img src={errorImage} alt="errorImage" />
                </div>
                <div className='left-error'>
                    <h2><span>Hey!</span> We know your situation.</h2>
                    <p><span>Be Relax!</span> You will get your dream job</p>
                    <div>
                        <Link to='/'>
                            <FontAwesomeIcon className='icon' icon={faArrowLeft}></FontAwesomeIcon>
                            Go Back
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;