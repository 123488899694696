import React from 'react';
import './CollegeTrust.css';
import announce from '../../../assets/images/announce.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CollegeTrust = () => {
    return (
        <div className='collegeTrust-part'>
            <div className='container inner-trust'>
                <div className='left-trust'>
                    <img src={announce} alt="announceImage" />
                </div>
                <div className='right-trust'>
                    <h2>Why <span>College</span> Trust Us</h2>
                    <p> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> <span data-aos="fade-left" data-aos-duration="1000">Streamlining placements end-to-end.</span> </p>
                    <p> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> <span data-aos="fade-left" data-aos-duration="2000">Build a healthy student-university relationship.</span></p>
                    <p> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> <span data-aos="fade-left" data-aos-duration="3000">Your data is safe with us.</span></p>
                </div>
            </div>
        </div>
    );
};

export default CollegeTrust;