import React, { useState } from 'react';
import './SpecificCourse.css';

// For Other Similar Slider Part
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { faCheck, faIndianRupeeSign, faPlay, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import topImage from '../../assets/images/topCourse.png';
import video from '../../assets/images/video.png';
import quiz from '../../assets/images/quiz.png';
import practice from '../../assets/images/practice.png';
import reach from '../../assets/images/reach.png';
import graph from '../../assets/images/graph.png';
import update from '../../assets/images/update.png';
import language from '../../assets/images/language.png';

// For Accordion Course Content
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Reviews from './Reviews/Reviews';

const SpecificCourse = () => {


    // Ui Course
    const courses = [
        {
            id: '01',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '02',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '03',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '04',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '05',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '06',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        }
    ];


    // Description
    const [description, setDescription] = useState(true);
    const descriptionHandle = e => {
        setDescription(true);
        setCourseContent(false);
        setReviews(false);
        setRecentlyHired(false);
        setRelatedJob(false);
        setInstructor(false);
    };

    // Course Content
    const [courseContent, setCourseContent] = useState(false);
    const contentHandle = e => {
        setDescription(false);
        setCourseContent(true);
        setReviews(false);
        setRecentlyHired(false);
        setRelatedJob(false);
        setInstructor(false);
    };

    // Reviews
    const [reviews, setReviews] = useState(false);
    const reviewHandle = e => {
        setDescription(false);
        setCourseContent(false);
        setReviews(true);
        setRecentlyHired(false);
        setRelatedJob(false);
        setInstructor(false);
    };

    // Download
    const [recentlyHired, setRecentlyHired] = useState(false);
    const hiredHandle = e => {
        setDescription(false);
        setCourseContent(false);
        setReviews(false);
        setRecentlyHired(true);
        setRelatedJob(false);
        setInstructor(false);
    };

    // Faqs
    const [relatedJob, setRelatedJob] = useState(false);
    const jobsHandle = e => {
        setDescription(false);
        setCourseContent(false);
        setReviews(false);
        setRecentlyHired(false);
        setRelatedJob(true);
        setInstructor(false);
    };

    // Instructor
    const [instructor, setInstructor] = useState(false);
    const instructorHandle = e => {
        setDescription(false);
        setCourseContent(false);
        setReviews(false);
        setRecentlyHired(false);
        setRelatedJob(true);
        setInstructor(true);
    };

    return (
        <div className='specificCourse-wrapper'>
            <div className="container inner-specificCourse">

                {/* Left Part */}
                <div>
                    <div className='left-content'>
                        <h2>The Complete UI/UX Course in 2022: Zero to  Hero</h2>
                        <p>Learn everything there is to know about Javascript - from scratch! Get job ready with a deep understanding of the internals of JS. Create a full E-Commerce app complete with authentication</p>
                    </div>

                    {/* Link Part */}
                    <div className='link-part'>
                        <span onClick={descriptionHandle} className={description ? 'active' : 'inactive'}>Description</span>
                        <span onClick={contentHandle} className={courseContent ? 'active' : 'inactive'}>Course Content</span>
                        <span onClick={reviewHandle} className={reviews ? 'active' : 'inactive'}>Reviews</span>
                        <span onClick={hiredHandle} className={recentlyHired ? 'active' : 'inactive'}>Recently Hired</span>
                        <span onClick={jobsHandle} className={relatedJob ? 'active' : 'inactive'}>Related Jobs</span>
                        <span onClick={instructorHandle} className={instructor ? 'active' : 'inactive'}>Instructor</span>
                    </div>

                    {/* Toggle Part */}
                    <div className='toggle-part'>

                        {/* Description Part */}
                        {
                            description && <div className='description'>
                                <h2>Overview</h2>
                                <div className='inner-description'>
                                    {/* Left Content */}
                                    <div className='left'>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Learn everything there is to know about Javascript - from scratch!</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Get job ready with a deep understanding of the internals of JS</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Create a full E-Commerce app complete with authentication</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Build your own physics-based JavaScript game</p>
                                        </div>
                                    </div>

                                    {/* Right Content */}
                                    <div className='right'>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Learn everything there is to know about Javascript - from scratch!</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Get job ready with a deep understanding of the internals of JS</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Create a full E-Commerce app complete with authentication</p>
                                        </div>
                                        <div className='content'>
                                            <span> <FontAwesomeIcon className='check-mark' icon={faCheck}></FontAwesomeIcon> </span>
                                            <p>Build your own physics-based JavaScript game</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Course Content Part */}
                        {
                            courseContent && <div className='course-content'>
                                <div>
                                    <Accordion className='accordion'>
                                        <AccordionSummary
                                            expandIcon={<ExpandCircleDownOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className='text'>Introduction</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='introduction'>
                                                <div>
                                                    <span>
                                                        <FontAwesomeIcon className="icon" icon={faPlay}></FontAwesomeIcon>
                                                        How to Start
                                                    </span>
                                                    <span>
                                                        <FontAwesomeIcon className="icon" icon={faPlay}></FontAwesomeIcon>
                                                        How websites work
                                                    </span>
                                                    <span>
                                                        <FontAwesomeIcon className="icon" icon={faPlay}></FontAwesomeIcon>
                                                        More about websites, Web3, D-Apps
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <FontAwesomeIcon className="icon" icon={faPlay}></FontAwesomeIcon>
                                                        How to install VS Code in windows
                                                    </span>
                                                    <span>
                                                        <FontAwesomeIcon className="icon" icon={faPlay}></FontAwesomeIcon>
                                                        How to install VS Code in MOCs
                                                    </span>
                                                    <span>
                                                        <img src={practice} alt="practice" />
                                                        Exercise 1
                                                    </span>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <AccordionSummary
                                            expandIcon={<ExpandCircleDownOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className='text'>HTML from Start</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                All the tests are absolutely free of charge! You can apply for unlimited number of times.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <AccordionSummary
                                            expandIcon={<ExpandCircleDownOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className='text'>CSS : from Beginners</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Click on Login button on the top right corner of this page . You will then be redirected to a
                                                page containing all the Job roles for which you can book the test. Click on your desired job role,
                                                a page containing more information about your job role will appear, there you can click on 'Book a slot'
                                                and you will be asked a few questions and you will be able to select a test date from the options shown
                                                to you. Then click on 'book a slot' and Voila !!! Your test is Booked!. Now get ready to take the Test!
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <AccordionSummary
                                            expandIcon={<ExpandCircleDownOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className='text'>CSS : Advance</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                In case, you don't clear a cutoff, you can always book another test .
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <AccordionSummary
                                            expandIcon={<ExpandCircleDownOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className='text'>Project 1 : Instagram Clone</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Once you pass any Level of the Unihire test, you can sit tight and wait for companies to invite
                                                you for interviews, It's that Simple.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        }

                        {/* Reviews Part */}
                        {
                            reviews && <Reviews></Reviews>
                        }
                    </div>
                </div>

                {/* Right Part */}
                <div className='right-part'>
                    <img src={topImage} alt="courseImage" />
                    <div className='btn-part'>
                        <Link to='/paymentPage'>BUY NOW</Link>
                        <span>
                            <FontAwesomeIcon className='share-icon' icon={faShareAlt}></FontAwesomeIcon>
                        </span>
                    </div>
                    <div className="details">
                        <p>
                            <img src={video} alt="iconImage" />
                            60+ Hours Lectures
                        </p>
                        <p>
                            <img src={quiz} alt="iconImage" />
                            10 Quizzes
                        </p>
                        <p>
                            <img src={practice} alt="iconImage" />
                            20+ Practice Exercise
                        </p>
                        <p>
                            <img src={reach} alt="iconImage" />
                            Reach Directly to Level 2
                        </p>
                        <p>
                            <img src={graph} alt="iconImage" />
                            Increase Your CTC up to 35 LPA
                        </p>
                        <p>
                            <img src={update} alt="iconImage" />
                            Last Update October, 2022
                        </p>
                        <p>
                            <img src={language} alt="iconImage" />
                            English and Hindi
                        </p>
                    </div>
                </div>
            </div>

            {/* Similar Part */}
            <div className="similar-part">
                <div className='container'>
                    <h2>Other Similar Courses</h2>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        speed={1000}
                        grabCursor='true'
                        autoplay={{
                            delay: 3000,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation, A11y, Autoplay]}
                        className="mySwiper"
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            767: {
                                slidesPerView: 2,
                            },
                            991: {
                                slidesPerView: 3,
                            }

                        }}
                    // loop='true'
                    >

                        {
                            courses.map(course => <SwiperSlide key={course.id} className='course-slide'>
                                <div className='course-slide-content'>
                                    <div>
                                        <img src={topImage} alt="userImage" />
                                    </div>
                                    <div className='course-content'>
                                        <h3> {course.title} </h3>
                                        <p> {course.text} </p>
                                    </div>
                                    <div className='content-bottom'>
                                        <h3> <FontAwesomeIcon className='rupee' icon={faIndianRupeeSign}></FontAwesomeIcon> {course.taka} </h3>
                                        <button>BUy Now</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            )
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default SpecificCourse;