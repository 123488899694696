import React from 'react';
import './UserProfile.css';
import test from '../../assets/images/test.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faClock, faDownload, faEllipsis, faEye, faPlay, faShareAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import testBottom from '../../assets/images/testSecond.png';
import testCourse from '../../assets/images/course.png';
import timer from '../../assets/images/timer.png';
import reschedule from '../../assets/images/clarity_note-edit-line.png';

// Toggle Part

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const UserProfile = () => {


    // Message Toggle Part
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        >
            <List>
                <div className='close-icon user-profile'>
                    <FontAwesomeIcon onClick={toggleDrawer(anchor, false)} className='icon' icon={faClose}></FontAwesomeIcon>
                </div>
                {/* Message Part */}
                <div className="message">
                    <h3>Message</h3>
                    <div>
                        <p> <span>Download Your Admit Card</span> <FontAwesomeIcon className='icon' icon={faArrowDown}></FontAwesomeIcon> </p>
                        <p> <span>Your Scores are Out</span> <FontAwesomeIcon className='icon' icon={faEye}></FontAwesomeIcon> </p>
                    </div>
                </div>
            </List>
        </Box>
    );

    // ********************

    // Course Data
    const courses = [
        {
            id: "01",
            name: "Node JS in 100 Days",
            ratings: "4.5"
        },
        {
            id: "02",
            name: "Node JS in 100 Days",
            ratings: "4.5"
        },
        {
            id: "03",
            name: "Node JS in 100 Days",
            ratings: "4.5"
        }
    ];

    // Test Data
    const tests = [
        {
            id: '01',
            title: 'UI/UX Design',
            status: 'Completed',
            level: 'Level 1',
            score: '95/100'
        },
        {
            id: '02',
            title: 'UI/UX Design',
            status: 'Completed',
            level: 'Level 1',
            score: '95/100'
        }
    ];

    return (
        <div className='test-wrapper'>

            {/* Message */}
            <div className='message-sidebar'>
                {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}> <span className='count'>2</span> Message</Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>

            <div className='container'>

                {/* Top Part */}
                <h2> Your Tests</h2>
                <div className="inner-test">
                    <div className='card upcoming-card'>

                        {/* Dots */}
                        <span className='dot-icon'>
                            <FontAwesomeIcon className='icon' icon={faEllipsis}></FontAwesomeIcon>
                        </span>
                        {/* ------ */}

                        <img src={test} alt="testImage" />
                        <h3>UI/UX Design</h3>
                        <p className='upcoming-test'>
                            <span>Upcoming</span>
                            <span>Level 2</span>
                        </p>
                        <p><small style={{ color: '#fff' }}>Your test is shortlisted at</small></p>
                        <div className='first-card'>
                            <p>
                                <FontAwesomeIcon style={{ color: '#fff' }} icon={faClock}></FontAwesomeIcon>
                                <span style={{ color: '#fff' }}>26 Oct, 2022</span>
                                <span style={{ color: '#fff' }} className='time'>10:00 AM</span>
                            </p>
                            <button className='first'>
                                <img src={reschedule} alt='rescheduleImage'></img>
                                Reschedule
                            </button>
                        </div>
                    </div>

                    {/* Completed Test */}
                    {
                        tests.map(tes =>
                            <div key={tes.id} className='card completed-card'>
                                <img src={test} alt="testImage" />
                                <h3> {tes.title} </h3>
                                <p className='completed-level'>
                                    <span> {tes.status} </span>
                                    <span> {tes.level} </span>
                                </p>
                                <div className='first-card'>
                                    <p>
                                        <img src={timer} alt='timerImage'></img>
                                        <span> {tes.score} </span>
                                    </p>
                                    <button className='first'>View Details</button>
                                </div>
                            </div>

                        )
                    }
                </div>

                {/* Course Part */}
                <div className='test-bottom-wrapper'>
                    <h2>Your Courses</h2>
                    <div className="test-bottom">
                        <div className="card">
                            <img src={testBottom} alt="testImage" />
                            <h3>Communication Skill Course</h3>
                            <p><small>20% Completed</small></p>
                            <div className='inner-card'>
                                <p><FontAwesomeIcon className='play-icon' icon={faPlay}></FontAwesomeIcon> 26/100 </p>
                                <button className='continue'>Continue <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> </button>
                            </div>
                        </div>
                        <div className="card">
                            <img src={testBottom} alt="testImage" />
                            <h3>Personality Development Course</h3>
                            <div className='inner-card inner-card-second'>
                                <p>
                                    <FontAwesomeIcon className="share" icon={faShareAlt}></FontAwesomeIcon>
                                    <FontAwesomeIcon className='download' icon={faDownload}></FontAwesomeIcon>
                                </p>
                                <p>
                                    <button className='start'>Start Over</button>
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <img src={testBottom} alt="testImage" />
                            <h3>Personality Development Course</h3>
                            <div className='inner-card inner-card-second'>
                                <p>
                                    <FontAwesomeIcon className="share" icon={faShareAlt}></FontAwesomeIcon>
                                    <FontAwesomeIcon className='download' icon={faDownload}></FontAwesomeIcon>
                                </p>
                                <p>
                                    <button className='start'>Start Over</button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Recommended Course Part */}
                <div className="test-course-part">
                    <h2>Recommended Courses</h2>
                    <div className='inner-test'>
                        {
                            courses.map(course => <div key={course.id} className='card'>
                                <img src={testCourse} alt="courseImage" />
                                <h3>Web Dev : {course.name} </h3>
                                <div className='inner-card'>
                                    <p>
                                        <FontAwesomeIcon className="share" icon={faShareAlt}></FontAwesomeIcon>
                                        <span>
                                            <FontAwesomeIcon icon={faStar}></FontAwesomeIcon> {course.ratings}
                                        </span>
                                    </p>
                                    <button className='buy-now'>Buy Now</button>
                                </div>
                            </div>

                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;