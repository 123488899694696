import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import './QuestionAnswer.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const QuestionAnswer = () => {
    return (
        <div className='question-answer'>
            <div className='container'>
                <h2><span>Frequently</span> Ask Question</h2>
                <div>
                    <Accordion className='accordion'>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='text'>Who can take the Level 1 test?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Anyone above the age of 18 with our partnered universities can apply (No Degree needed).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion'>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='text'>Anyone above the age of 18 with our partnered universities can apply (No Degree needed)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                All the tests are absolutely free of charge! You can apply for unlimited number of times.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion'>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='text'>How do I register for the Level 1  Test?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Click on Login button on the top right corner of this page . You will then be redirected to a
                                page containing all the Job roles for which you can book the test. Click on your desired job role,
                                a page containing more information about your job role will appear, there you can click on 'Book a slot'
                                and you will be asked a few questions and you will be able to select a test date from the options shown
                                to you. Then click on 'book a slot' and Voila !!! Your test is Booked!. Now get ready to take the Test!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion'>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='text'>What if I don't clear the cutoff ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                In case, you don't clear a cutoff, you can always book another test .
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='accordion'>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='text'>How do I get jobs thorugh Unihire?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Once you pass any Level of the Unihire test, you can sit tight and wait for companies to invite
                                you for interviews, It's that Simple.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default QuestionAnswer;