import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/fontawesome-free-brands';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='top-footer'>
                <div className="container">
                    <div className='inner-footer'>
                        <div>
                            <h2>Unihire.org</h2>
                            <h4>info@unihire.org</h4>
                            <p>9, Matrix Layout,
                                Marthahalli, <br />
                                Bangalore -
                                560037, karnataka, India</p>
                        </div>
                        <div>
                            <h3>Company</h3>
                            <ul>
                                <li>
                                    <Link to='/'>SignUp</Link>
                                    <Link to='/'>Login</Link>
                                    <Link to='/'>Help</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Colleges</h3>
                            <ul>
                                <li>
                                    <Link to='/'>SignUp</Link>
                                    <Link to='/'>Login</Link>
                                    <Link to='/'>Help</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Courses</h3>
                            <ul>
                                <li>
                                    <Link to='/'>Python</Link>
                                    <Link to='/'>Java</Link>
                                    <Link to='/'>C++</Link>
                                    <Link to='/'>.Net</Link>
                                    <Link to='/'>Mobile App Development</Link>
                                    <Link to='/'>Web Designing</Link>
                                    <Link to='/'>SEO Courses</Link>
                                    <Link to='/'>Cloud Services</Link>
                                    <Link to='/'>Fullstack Development</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container bottom-footer'>
                <div>
                    <p>&copy; All Right Reserved - By Unihire</p>
                </div>
                <div className='social-icon'>
                    <FontAwesomeIcon className='icon facebook' icon={faFacebookF}></FontAwesomeIcon>
                    <FontAwesomeIcon className='icon' icon={faTwitter}></FontAwesomeIcon>
                    <FontAwesomeIcon className='icon' icon={faLinkedinIn}></FontAwesomeIcon>
                    <FontAwesomeIcon className='icon' icon={faInstagram}></FontAwesomeIcon>
                </div>
            </div>
        </div>
    );
};

export default Footer;