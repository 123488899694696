import React from 'react';
import './Cards.css';
import student from '../../../assets/images/student.png';
import college from '../../../assets/images/college.png';
import company from '../../../assets/images/company.png';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';

// For Login Modal
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

// Modal Style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 350,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

const Cards = () => {

    // Modal State Statement
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Form Click Handle
    const clickHandler = (event) => {
        // window.location.href = 'https://unihire-college.web.app/';
    }

    return (
        <div className='container cards'>
            <div data-aos="zoom-in" data-aos-duration="1000" className='inner-cards'>
                <img src={student} alt="icon" />
                <h2>Student</h2>
                <p>Build a healthy student-university relationship</p>
                <div className='cards-button'>
                    <Button className='student-btn' onClick={handleOpen}>login</Button>
                </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1000" className='inner-cards'>
                <img src={college} alt="icon" />
                <h2>College</h2>
                <p>Take your placement cell online</p>
                <div className='cards-button'>
                    <ExternalLink className='card-link' href='https://unihire-college.web.app/'>Login</ExternalLink>
                </div>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1000" className='inner-cards third-card'>
                <img src={company} alt="icon" />
                <h2>Company</h2>
                <p>End-to-end virtual campus hiring</p>
                <div className='cards-button'>
                    <button type='submit'> <Link to='/jobs'>Log In</Link> </button>
                </div>
            </div>
            {/* Modal Part */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                        />
                        <div className='login-div'>
                            <Button className='button' onClick={clickHandler}>Log In</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default Cards;