import React from 'react';
import './Execution.css';
import leftExe from '../../../assets/images/leftExe.png';
import rightExe from '../../../assets/images/rightExe.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Execution = () => {
    return (
        <div className='execution-part'>
            <div className='container'>
                <h2>How does it <span>work?</span></h2>
                <p>There are 3 levels of examination.</p>
                <div className='inner-execution'>
                    <div className='top'>
                        <img src={leftExe} alt='leftExe' />
                        <h3>Explore Courses</h3>
                        <button className='top-btn' type="submit"> <Link className='explore-link' to='/jobs'>Explore</Link>  <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
                    </div>
                    <div className='middle'>
                        <p>Three levels of</p>
                        <p className='bold'>Exam</p>
                    </div>
                    <div className='bottom'>
                        <img src={rightExe} alt='rightExe' />
                        <h3>Latest Job Options</h3>
                        <button type='submit'> <Link className='explore-link' to='/jobs'>Explore</Link> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> </button>
                    </div>
                </div>

                {/* Start Level 1 */}
                <div data-aos="fade-left" data-aos-duration="1000" className='level-first'>
                    <span>1</span>
                    <div className='inner-level'>
                        <h3>Level 1 Examination</h3>
                        <p>Anyone can apply for examination to test their skills and Get Hired by companies</p>
                        <div className='button'>
                            <button type='submit'> <Link to='/jobs'>Coming Soon ...
                                {/* <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> */}
                            </Link>
                            </button>
                        </div>
                    </div>
                </div>
                {/* End Level 1 */}

                {/* Start Level 2 */}
                <div data-aos="fade-right" data-aos-duration="1000" className='level-second'>
                    <div className='inner-level'>
                        <h3>Level 2 Examination</h3>
                        <p>Students who scored 700+ in L1 exam are eligible to apply for L2</p>
                        <div className='button'>
                            <button type='submit'> <Link to='/jobs'>Coming Soon ...
                                {/* <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> */}
                            </Link> </button>
                        </div>
                    </div>
                    <span>2</span>
                </div>
                {/* End Level 2 */}

                {/* Start Level 3 */}
                <div data-aos="fade-left" data-aos-duration="1000" className='level-first third-level'>
                    <span>3</span>
                    <div className='inner-level'>
                        <h3>Level 3 Examination</h3>
                        <p>Students who scored atleast 500 in L2 exam are eligible to apply for L3</p>
                        <div className='button'>
                            <button type='submit'> <Link to='/jobs'>Coming Soon ...
                                {/* <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> */}
                            </Link> </button>
                        </div>
                    </div>
                </div>
                {/* End Level 3 */}
            </div>
        </div>
    );
};

export default Execution;