import React, { useState } from 'react';
import './Header.css';
import profile from '../../../assets/images/rajib.png';

import { Link, NavLink } from 'react-router-dom';
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom';
// import {login} from '../../../service/auth';
// For Login Modal
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Modal Style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 350,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};
const initial_login_state = {
    email: "",
    password: ""
}

const Header = () => {

    // Navigate After Login
    const navigate = useNavigate();

    // Profile Handle
    const [profiles, setProfiles] = useState(false);
    const profileHandle = () => {
        setProfiles(true);
    }

    // To get user Profile
    const userProfileHandle = () => {
        navigate('/userProfile');
        setProfiles(false);
    }

    // Modal State Statement
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState(initial_login_state);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    // After clicking login button there set a local storage item
    const clickHandler = (event) => {
        localStorage.setItem('login', JSON.stringify('logedIn'));
        navigate('/');
        handleClose();
    }

    // And it will use the data from local storage to login
    // const login = localStorage.getItem('login');
    const login = localStorage.getItem('token');

    // Log out
    const logOut = e => {
        localStorage.clear();
        setProfiles(false);
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const mutation = useMutation(login, {
        onSuccess: async (res) => {
            const data = (await res.json());
            localStorage.setItem('token', data.token);
            localStorage.setItem('user_id', data._id);
            navigate("/dashboard");
            handleClose();
        },
        onError: (err) => {
            console.log(err.message);
        }
    })

    const handleLogin = (e) => {
        e.preventDefault();
        mutation.mutate({
            email: state.email,
            password: state.password,
        })
    }

    return (
        <header>
            <div className='container'>
                <nav>
                    <div className='logo'>
                        <Link to='/'>Unihire.org</Link>
                    </div>
                    <div className='menu'>
                        <NavLink to='/jobs' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Jobs</NavLink>
                        <NavLink to='/course' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Course</NavLink>
                        {
                            // If login function is true then login options is work
                            login ? <div onClick={profileHandle} className='profile-btn'>
                                <img src={profile} alt="profile" /> <FontAwesomeIcon className='profile-down-icon' icon={faAngleDown}></FontAwesomeIcon>
                            </div> : <Button onClick={handleOpen}>login</Button>
                        }

                        {/* Profile Board */}
                        {
                            profiles && <div className="profile-board">
                                <div className="inner-profile-wrapper">
                                    <div>
                                        <img src={profile} alt="profileImage" />
                                    </div>
                                    <div>
                                        <h4>Md.Rajebul Alam</h4>
                                        <p>rajebulalam01@gmail.com</p>
                                    </div>
                                </div>
                                <button onClick={userProfileHandle} className='btn'>View Profile</button>
                                <button onClick={userProfileHandle} className='btn'>Your Courses</button>
                                <button onClick={logOut} className='btn logOut'>Logout</button>
                            </div>
                        }
                    </div>
                </nav>
            </div >

            {/* Modal Part */}
            <Modal Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            name="email"
                            value={state.email}
                            variant="standard"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Password"
                            type="password"
                            fullWidth
                            name="password"
                            value={state.password}
                            onChange={handleChange}
                            variant="standard"
                        />
                        <div className='login-div'>
                            {/* <Button className='button' onClick={clickHandler}>Log In</Button> */}
                            <Button className='button' onClick={(e) => handleLogin(e)}>Log In</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal >
        </header >
    );
};

export default Header;