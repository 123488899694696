import React from 'react';
import './CourseUi.css';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import topImage from '../../assets/images/topCourse.png';
import { faCheckDouble, faIndianRupeeSign, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import allCourse from '../../assets/images/allCourse.png';
import quiz from '../../assets/images/quiz.png';
import video from '../../assets/images/video.png';
import practice from '../../assets/images/practice.png';
import { Link } from 'react-router-dom';

const CourseUI = () => {

    // Ui Course
    const courses = [
        {
            id: '01',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '02',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '03',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '04',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '05',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        },
        {
            id: '06',
            title: 'The complete UI/UX course in 2022: Zero to Hero',
            text: 'Unihire.org',
            taka: '4999'
        }
    ];

    // All Course
    const allCourses = [
        {
            id: '01',
            title: 'The Complete UI/UX Course in 2022: Zero to Hero',
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi eius labore eveniet mollitia dolor id quod, alias illo facere, est itaque a porro temporibus.",
        },
        {
            id: '02',
            title: 'The Complete UI/UX Course in 2022: Zero to Hero',
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi eius labore eveniet mollitia dolor id quod, alias illo facere, est itaque a porro temporibus."
        },
        {
            id: '03',
            title: 'The Complete UI/UX Course in 2022: Zero to Hero',
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi eius labore eveniet mollitia dolor id quod, alias illo facere, est itaque a porro temporibus."
        }
    ];

    return (
        <div className='course-ui-wrapper'>

            {/* Popular Course */}
            <div className='popular-course'>
                <h2>Popular Course</h2>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    speed={1000}
                    grabCursor='true'
                    autoplay={{
                        delay: 3000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation, A11y, Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        767: {
                            slidesPerView: 2,
                        },
                        991: {
                            slidesPerView: 3,
                        }

                    }}
                // loop='true'
                >


                    {
                        courses.map(course => <SwiperSlide key={course.id} className='course-slide'>
                            <div className='course-slide-content'>
                                <div>
                                    <img src={topImage} alt="userImage" />
                                </div>
                                <div className='course-content'>
                                    <h3> {course.title} </h3>
                                    <p> {course.text} </p>
                                </div>
                                <div className='content-bottom'>
                                    <h3> <FontAwesomeIcon className='rupee' icon={faIndianRupeeSign}></FontAwesomeIcon> {course.taka} </h3>
                                    <Link to={course.id}>Buy Now</Link>
                                </div>
                            </div>
                            {/* Hover Course */}
                            <div className='course-hover'>
                                <div className='inner-hover'>
                                    <h2>What you learn</h2>
                                    <div className='btn'>
                                        <span className='level'>
                                            Level 1
                                        </span>
                                        <span className='ratings'>
                                            <FontAwesomeIcon className='icon' icon={faStar}></FontAwesomeIcon>
                                            4.5
                                        </span>
                                    </div>
                                    <p>Learn from real NodeJS experts! Includes REALLY Advanced NodeJS. Express, GraphQL, REST, MongoDB, SQL, MERN + much more</p>
                                    <li>
                                        <p> <FontAwesomeIcon className='check' icon={faCheckDouble}></FontAwesomeIcon> Build enterprise level Node applications and deploy to the cloud (AWS)</p>
                                        <p> <FontAwesomeIcon className='check' icon={faCheckDouble}></FontAwesomeIcon> Learn to build secure and performant, large scale applications like a senior backend developer</p>
                                        <p> <FontAwesomeIcon className='check' icon={faCheckDouble}></FontAwesomeIcon> Lead NodeJS projects by making good architecture decisions and helping others on your team</p>
                                    </li>
                                </div>
                            </div>
                        </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>

            {/* All Course */}
            <div className="all-course-wrapper">
                <h2>All Courses</h2>
                <div className="all-course">
                    {/* Left Part */}
                    <div className='left'>
                        <h3>Filter</h3>
                        <div className='top-course'>
                            <input type="checkbox" name="level" id="level" value='Level 1' /><label htmlFor="level">Level 1</label> <br />
                            <input type="checkbox" name="level" id="level" value='level 2' />
                            <label htmlFor="level">Level 2</label> <br />
                            <input type="checkbox" name="level" id="level" value='level 3' />
                            <label htmlFor="level">Level 3</label> <br />
                        </div>
                        <div>
                            <input type="checkbox" name="level" id="level-circle" value='Level 1' /><label htmlFor="level">4.5 & Above</label> <br />
                            <input type="checkbox" name="level" id="level-circle" />
                            <label htmlFor="level">4.0 & Above</label> <br />
                            <input type="checkbox" name="level" id="level-circle" />
                            <label htmlFor="level">3.5 & Above</label> <br />
                        </div>
                    </div>
                    {/* Right Part */}
                    <div className='right-wrapper'>
                        {
                            allCourses.map(allCor => <div key={allCor.id} className='right'>
                                <div>
                                    <img src={allCourse} alt="allCourseImage" />
                                </div>
                                <div>
                                    <h3>{allCor.title}</h3>
                                    <p> {allCor.description} </p>

                                    {/* Icon Part */}
                                    <div className='icon-part'>
                                        <span>
                                            <img src={video} alt="icon" />
                                            60+ hours lectures
                                        </span>
                                        <span>
                                            <img src={quiz} alt="icon" />
                                            10 quizzes
                                        </span>
                                        <span>
                                            <img src={practice} alt="icon" />
                                            20+ practice exercise
                                        </span>
                                    </div>

                                    {/* Ratings & Button */}
                                    <div className='bottom-part'>
                                        <div>
                                            <span className='icon-wrap'>
                                                <FontAwesomeIcon className='icon' icon={faStar}></FontAwesomeIcon>
                                            </span>
                                            <span className='level'>
                                                Level 1
                                            </span>
                                        </div>
                                        <div className='button'>
                                            <span>
                                                <FontAwesomeIcon className='rupee' icon={faIndianRupeeSign}></FontAwesomeIcon>
                                                4999
                                            </span>
                                            <button>BUY NOW</button>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseUI;