import { faPaypal } from '@fortawesome/fontawesome-free-brands';
import { faBank, faCreditCardAlt, faWallet, faArrowRotateBack, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './PaymentPage.css';
import courseImage from '../../assets/images/topCourse.png';
import SuccessNotify from '../../Pages/Shared/Notification/SuccessNotification/SuccessNotify';
import ErrorNotification from '../../Pages/Shared/Notification/ErrorNotification/ErrorNotification';

// Modal
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import payment from '../../assets/images/payment.png';

const PaymentPage = () => {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Card Details
    const [card, setCard] = useState(false);
    const cardHandle = () => {
        setCard(true);
    }

    // Testing Purpose for Success Notification
    const [successNotification] = SuccessNotify();

    // Testing Purpose for Error Notification
    const [errorNotification] = ErrorNotification();

    const [notify, setNotify] = useState(false);

    const paymentHandle = () => {
        // setNotify(true);
    }

    return (
        <div className='payment-wrapper'>

            {/* Testing Purpose */}
            {notify && errorNotification}

            <div className='container'>
                <Link to='/course' className='back-link'>
                    <FontAwesomeIcon className='icon' icon={faArrowRotateBack}></FontAwesomeIcon>
                    Back
                </Link>
            </div>
            <div className="container inner-payment">
                <div className='left'>
                    <div>
                        <div className='card-details'>
                            <span onClick={cardHandle}> <FontAwesomeIcon className='icon' icon={faCreditCardAlt} ></FontAwesomeIcon> Credit / Debit Card</span>
                            {
                                card && <form className='card-form'>
                                    <div>
                                        <label htmlFor="cardName">Name on Card</label>
                                        <input type="text" name="cardName" id="cardName" placeholder='Unihire' />
                                    </div>
                                    <div>
                                        <label htmlFor="cardNo">Card No.</label>
                                        <input type="text" name="cardNo" id="cardNo" placeholder='000 000 000 000 000 0000' />
                                    </div>
                                    <div className='date-cvc'>
                                        <div>
                                            <label htmlFor="date">Date of Expire</label>
                                            <input type="date" name="date" id="cardNo" />
                                        </div>
                                        <div>
                                            <label htmlFor="cvc">CVV/CVC</label>
                                            <input type="text" name="cvc" id="cvc" placeholder='XXX' />
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                        <span> <FontAwesomeIcon className='icon' icon={faBank} ></FontAwesomeIcon> Net Banking</span>
                        <span> <FontAwesomeIcon className='icon' icon={faWallet} ></FontAwesomeIcon> Wallet</span>
                        <span> <FontAwesomeIcon className='icon' icon={faPaypal} ></FontAwesomeIcon> Paypal</span>
                    </div>
                    {/* Items */}
                    <div className='items-wrapper'>
                        <h2>Items:</h2>
                        <div className='items'>
                            <div>
                                <img src={courseImage} alt="itemImage" />
                                <h3>The UI/UX Bootcamp: Zero to Hero</h3>
                            </div>
                            <h4>3499</h4>
                        </div>
                    </div>
                </div>

                {/* Right Part */}
                <div className='right'>
                    <h2>Summary</h2>
                    <div className='course-fee'>
                        <span>Course Fee:</span>
                        <span>3499</span>
                    </div>
                    <div className='total'>
                        <span>Total:</span>
                        <span>3499</span>
                    </div>
                    <p>By completing your purchase you agree to these <span>Terms of Service</span> </p>
                    {/* <button onClick={paymentHandle} type="submit">Complete Checkout</button> */}
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Complete Checkout
                    </Button>
                    <p>15 Days money back guarantee</p>
                </div>
            </div>

            {/* Error */}
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent className='payment-error'>
                        <FontAwesomeIcon onClick={handleClose} className='x-icon' icon={faXmark}></FontAwesomeIcon>
                        <img src={payment} alt="paymentError" />
                        <p>Sorry, but your payment <span>failed</span></p>
                        <div className='btn'>
                            <Button>Retry</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

        </div>
    );
};

export default PaymentPage;