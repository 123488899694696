import React from 'react';
import './RecentlyHired.css';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import jobPerson from '../../assets/images/jobPerson.png';
import microsoft from '../../assets/images/logos_microsoft-icon.png';

const RecentlyHired = () => {

    const courses = [
        {
            id: '01',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '02',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '03',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '04',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '05',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '06',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '07',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '08',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '09',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        },
        {
            id: '10',
            text: 'Introduction to Web Development with HTML, CSS, JavaScript'
        }
    ];

    return (
        <div className='recently-hired-wrapper'>
            <div>
                <Swiper
                    slidesPerView={6}
                    spaceBetween={20}
                    speed={1000}
                    grabCursor='true'
                    autoplay={{
                        delay: 2000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation, A11y, Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                        },
                        536: {
                            slidesPerView: 3,
                        },
                        767: {
                            slidesPerView: 4,
                        },
                        991: {
                            slidesPerView: 5,
                        },
                        1140: {
                            slidesPerView: 6
                        }
                    }}
                >

                    {
                        courses.map(course => <SwiperSlide key={course.id} className='new-recently-slide'>
                            <div>
                                <img className='jobPerson' src={jobPerson} alt="img" />
                                <div className='inner-recently'>
                                    <img src={microsoft} alt="subImage" />
                                    <span>microsoft</span>
                                </div>
                            </div>
                        </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default RecentlyHired;