import React from 'react';
import './Contact.css';
import contact from '../../../assets/images/contact.png';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        >
            <List>
                <div className='close-icon'>
                    <FontAwesomeIcon onClick={toggleDrawer(anchor, false)} className='icon' icon={faClose}></FontAwesomeIcon>
                </div>
                <div className='form'>
                    <div className='left-form'>
                        <h2>Contact Us</h2>
                        <p>Need some assistance in hiring process or campus drive ?
                            Reach out to us.</p>
                        <form>
                            <input type="text" name="companyName" id="companyName" placeholder='Name' />
                            <input type="text" name="natureBusiness" id="natureBusiness" placeholder='Nature of Business' />
                            <div className='form-inline'>
                                <input type="text" name="address" id="address" placeholder='Address' />
                                <input type="text" name="code" id="code" placeholder='Post Code' />
                            </div>
                            <input type="text" name="contactName" id="contactName" placeholder='Contact Name' />
                            <input type="text" name="contactPhone" id="contactPhone" placeholder='Contact Phone' />
                            <input type="email" name="email" id="email" placeholder='Email' />
                            <input type="text" name="linkedIn" id="linkedIn" placeholder='Linked In' />
                            <div>
                                <p className='message'>Message</p>
                                <textarea name="message" id="message" cols="30" rows="10"></textarea>
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                    <div className='right-form'>
                        <h3>Offices</h3>
                        <p>
                            9, Matrix Layout, Marthahalli, <br />
                            Bangalore - 560037, karnataka, India.
                        </p>
                    </div>
                </div>
            </List>
        </Box>
    );


    return (
        <div className='contact'>
            <div className='container'>
                <div data-aos="fade-right" data-aos-duration="1000" className='left-contact'>
                    <h2>Contact Us</h2>
                    <p>Need some assistance in hiring process or campus drive ? <br />
                        Reach out to us</p>
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button onClick={toggleDrawer(anchor, true)}>Contact Us</Button>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
                <div data-aos="fade-left" data-aos-duration="1000" className='right-contact'>
                    <img src={contact} alt="contact" />
                </div>
            </div>
        </div>
    );
};

export default Contact;