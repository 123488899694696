import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'; import { NavLink, Outlet } from 'react-router-dom';
import Loader from '../../Shared/Loader/Loader';
import LoaderTiming from '../../Shared/LoaderTiming/LoaderTiming';
import './Course.css';

const Course = () => {

    // Loader Component
    const [loader] = Loader();

    // Loading Time for the load and reload page
    const [loading] = LoaderTiming();

    return (
        <div>
            {
                loading ? loader : <div className="course-wrapper-part">

                    {/* Link Part */}
                    <div>
                        <ul className="course-link">
                            <li className="container">
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course'>UI/UX</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course/webDevelopment'>Web Development</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course/business'>Business</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course/sales'>Sales</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course/marketing'>Marketing</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/course/management'>Management</NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className='container'>
                        {/* Search */}
                        <div className="course-search">
                            <div className='inner-search'>
                                <FontAwesomeIcon className='search-icon' icon={faSearch}></FontAwesomeIcon>
                                <input type="search" name="search" id="search" placeholder='Search here ...' />
                            </div>
                        </div>

                        {/* Outlet Part */}
                        <div>
                            <Outlet></Outlet>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Course;