import React, { useEffect } from 'react';
import Banner from '../Banner/Banner';
import Cards from '../Cards/Cards';
// import Certified from '../Certified/Certified';
import CollegeTrust from '../CollegeTrust/CollegeTrust';
import Execution from '../Execution/Execution';
import QuestionAnswer from '../QuestionAnswer/QuestionAnswer';
import Slider from '../Slider/Slider';
import Contact from '../../Shared/Contact/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

    // Used for Animation
    useEffect(() => {
        AOS.init({
            easing: 'ease',
            once: false,
        });
    }, [])

    return (
        <div>
            <Banner></Banner>
            {/* <Certified></Certified> */}
            <CollegeTrust>
            </CollegeTrust>
            <Cards></Cards>
            <Execution></Execution>
            <QuestionAnswer></QuestionAnswer>
            <Slider></Slider>
            <Contact></Contact>
        </div>
    );
};

export default Home;