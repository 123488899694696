import React from 'react';
import Loader from '../Shared/Loader/Loader';
import LoaderTiming from '../Shared/LoaderTiming/LoaderTiming';
import JobBanner from '../../components/JobBanner/JobBanner';
import JobDisplay from '../../components/JobDisplay/JobDisplay';
import './Jobs.css';

const Jobs = () => {

    // Loader Component
    const [loader] = Loader();

    // Loading Time for the load and reload page
    const [loading] = LoaderTiming();

    return (
        <div>
            {loading ? loader : <div>
                <JobBanner></JobBanner>
                <JobDisplay></JobDisplay>
            </div>
            }
        </div>
    );
};

export default Jobs;