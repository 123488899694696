import React from 'react';
import './ComingSoon.css';
import {register} from '../../service/auth';

const ComingSoon = () => {
    return (
        <div className='coming-soon'>
            <h2>Coming Soon...</h2>
        </div>
    );
};

export default ComingSoon;