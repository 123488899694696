import React from 'react';
import './JobBanner.css';

const JobBanner = () => {
    return (
        <div className='job-banner-wrapper'>
            <h2>Get Hired</h2>
        </div>
    );
};

export default JobBanner;