import { useEffect, useState } from 'react';
import './Loader.css';
import { Bars } from 'react-loader-spinner';

const Loader = () => {

    const [loader, setLoader] = useState([]);
    useEffect(() => {
        setLoader(
            <div className='loader'>
                {/* Where you want to use loader, there you should use this */}
                <Bars className='bars' height="80"
                    width="60"
                    color="#3a3a3a"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true} />
            </div>
        );
    }, [])

    return [loader, setLoader];
};

export default Loader;