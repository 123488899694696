import React from 'react';
import './Reviews.css';
import reviewImage from '../../../assets/images/rivew.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Review Slide
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Reviews = () => {

    // Reviews
    const allReviews = [
        {
            id: '01',
            name: 'Josh Dani',
            described: 'Colt Steele was kind and knew how to speak to the viewer properly, he had a knowledge of details and filled them in properly and anticipated questions and concerns in a timely fashion',
            ratings: '4.5'
        },
        {
            id: '02',
            name: 'Hemnat',
            described: 'Colt Steele was kind and knew how to speak to the viewer properly, he had a knowledge of details and filled them in properly and anticipated questions and concerns in a timely fashion',
            ratings: '4.5'
        },
        {
            id: '03',
            name: 'Hemnat',
            described: 'Colt Steele was kind and knew how to speak to the viewer properly, he had a knowledge of details and filled them in properly and anticipated questions and concerns in a timely fashion',
            ratings: '4.5'
        },
        {
            id: '04',
            name: 'Hemnat',
            described: 'Colt Steele was kind and knew how to speak to the viewer properly, he had a knowledge of details and filled them in properly and anticipated questions and concerns in a timely fashion',
            ratings: '4.5'
        }
    ]

    return (
        <div className='reviews'>
            <Swiper
                slidesPerView={2}
                spaceBetween={20}
                speed={1000}
                grabCursor='true'
                autoplay={{
                    delay: 2000,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation, A11y, Autoplay]}
                className="mySwiper"
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    767: {
                        slidesPerView: 2,
                    }

                }}
            >

                {
                    allReviews.map(review => <SwiperSlide key={review.id} className='inner-review'>
                        <div className='head'>
                            <img src={reviewImage} alt="reviewImage" />
                            <h3> {review.name} </h3>
                        </div>
                        <p> {review.described} </p>
                        <div className='ratings'>
                            <span>
                                {review.ratings}
                                <FontAwesomeIcon className='icon' icon={faStar}></FontAwesomeIcon>
                            </span>
                        </div>
                    </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    );
};

export default Reviews;