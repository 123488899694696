import React, { useState } from 'react';
import './JobDashboard.css';
import uiImg from '../../assets/images/ui.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faSquareMinus, faFileAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RecentlyHired from '../RecentlyHired/RecentlyHired';
import wirefarming from '../../assets/images/wirfarmin.png';
import figma from '../../assets/images/figma.png';
import structure from '../../assets/images/structure.png';
import guideline from '../../assets/images/guideline.png';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import calendar from '../../assets/images/calendar.png';

// Dialouge 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// Calendar Part
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';

const UIDesign = () => {

    // Calendar Date
    const [date, setDate] = useState(new Date());

    // Submit Handle
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // Recently Hired
    const [recentlyHired, setRecentlyHired] = useState(true);
    const hiredHandle = e => {
        setRecentlyHired(true);
        setSkills(false);
        setStructures(false);
        setDownload(false);
        setFaq(false);
    }

    // Skills Required
    const [skills, setSkills] = useState(false);
    const skillHandle = e => {
        setRecentlyHired(false);
        setSkills(true);
        setStructures(false);
        setDownload(false);
        setFaq(false);
    }

    // Test Structure
    const [structures, setStructures] = useState(false);
    const structureHandle = e => {
        setRecentlyHired(false);
        setSkills(false);
        setStructures(true);
        setDownload(false);
        setFaq(false);
    }

    // Download
    const [download, setDownload] = useState(false);
    const downloadHandle = e => {
        setRecentlyHired(false);
        setSkills(false);
        setStructures(false);
        setDownload(true);
        setFaq(false);
    }

    // Faqs
    const [faq, setFaq] = useState(false);
    const faqHandle = e => {
        setRecentlyHired(false);
        setSkills(false);
        setStructures(false);
        setDownload(false);
        setFaq(true);
    }

    // Faqs Part
    const faqs = [
        {
            id: "01",
            question: "What do I need to have to take the Business Development Test?",
            answer: ""
        },
        {
            id: "02",
            question: "Will the test will be conducted in English ?",
            answer: ""
        },
        {
            id: "03",
            question: "What if I fail in level 1 examination ?",
            answer: ""
        },
        {
            id: "04",
            question: "Is the test free ?",
            answer: "Yes, the test is absolutly free."
        }
    ];

    // Calendar Part As Drawer
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        >
            <List>
                <div className='close-icon'>
                    <FontAwesomeIcon onClick={toggleDrawer(anchor, false)} className='icon' icon={faClose}></FontAwesomeIcon>
                </div>
                <div className="calendar-wrapper">
                    <div className="inner-calendar-part">
                        <div className='calendar'>
                            <div>
                                <img src={calendar} alt="calendar" />
                                <h3>UI/UX Design test</h3>
                                <select name="language" id="language">
                                    <option value="Language">Language</option>
                                    <option value="Language">Html 5</option>
                                    <option value="Language">Css 3</option>
                                    <option value="Language">Bootstrap</option>
                                    <option value="Language">Tailwind</option>
                                    <option value="Language">Material UI</option>
                                    <option value="Language">Material Table</option>
                                    <option value="Language">JavaScript</option>
                                    <option value="Language">ES6</option>
                                    <option value="Language">DOM Manipulation</option>
                                    <option value="Language">React JS</option>
                                    <option value="Language">React Router</option>
                                    <option value="Language">React Hook Form</option>
                                </select>
                                <p>Change your test <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></p>
                            </div>
                            <div>
                                <div className='day-picker'>
                                    <DayPicker
                                        mode="single"
                                        selected={date}
                                        onSelect={setDate}
                                    />
                                    {/* <p>You have selected : {format(date, 'PP')}</p> */}
                                    <div className="calendar-time">
                                        <p> <em>Time</em> </p>
                                        <p>
                                            {/* <input type='time' className='time'>10:00</input> */}
                                            <input type="time" className='time' name="time" id="time" title='Set your time' />
                                            {/* <span className='time'>
                                                <span className='am'>PM</span>
                                                <span className='pm'>AM</span>
                                            </span> */}
                                        </p>
                                    </div>
                                </div>

                                {/* Dialouge */}
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you want to proceed?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={handleClose} autoFocus>
                                            Ok
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className='calendar-btn'>
                            <Button onClick={handleClickOpen}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </List>
        </Box>
    );

    return (
        <div className='ui-design-wrapper'>
            {/* Top Part */}
            <div className='inner-ui-design'>
                <div>
                    <h2>Web UI/UX Jobs</h2>
                    <p> <FontAwesomeIcon className='ui-icon' icon={faBox}></FontAwesomeIcon> 100+ Jobs</p>
                    <p> <FontAwesomeIcon className='ui-icon' icon={faSquareMinus}></FontAwesomeIcon> Minimum 3+ LPA</p>
                    <p> <FontAwesomeIcon className='ui-icon' icon={faSquareMinus}></FontAwesomeIcon> Minimum 3+ LPA</p>

                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button className='banner-btn' onClick={toggleDrawer(anchor, true)}>Apply Now</Button>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}

                </div>
                <div className='top-image'>
                    <img src={uiImg} alt="uiImage" />
                </div>
            </div>

            {/* Link Part */}
            <div className='link-part'>
                <span onClick={hiredHandle} className={recentlyHired ? 'active' : 'inactive'}>Recently Hired</span>
                <span onClick={skillHandle} className={skills ? 'active' : 'inactive'}>Skill Required</span>
                <span onClick={structureHandle} className={structures ? 'active' : 'inactive'}>Test Structure</span>
                <span onClick={downloadHandle} className={download ? 'active' : 'inactive'}>Downloads</span>
                <span onClick={faqHandle} className={faq ? 'active' : 'inactive'}>FAQs</span>
            </div>
            <div>
                <div className='job-ui-details'>

                    {/* Recently Hired */}
                    {
                        recentlyHired && <div>
                            <div>
                                <h2>Recently Hired</h2>
                                <RecentlyHired></RecentlyHired>
                            </div>
                        </div>
                    }

                    {/* Skill required */}
                    {
                        skills && <div className='skill-require-wrapper'>
                            <h2>Skill required</h2>
                            <div className="skill-required">
                                <div>
                                    <img src={wirefarming} alt="iconImage" />
                                    <h5>Wireframing</h5>
                                    <p>Show your designs just like it is real time website.</p>
                                </div>
                                <div>
                                    <img src={figma} alt="iconImage" />
                                    <h5>Figma / Adobe XD</h5>
                                    <p>Show your designs just like it is real time website.</p>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Test Structure */}
                    {
                        structures &&
                        <div className='test-structure-wrapper'>
                            <h2>Test Structure</h2>
                            <h4>Level 1</h4>
                            <p>Score 75 out of 100 to qualify level 2 test</p>
                            <div className="test-structure">
                                <div className='inner-test-structure'>
                                    <span>Level 1</span>
                                    <div>
                                        <h3>Prove your Designing Skill </h3>
                                        <p>An MCQ  based examination to test your designing skill.</p>
                                    </div>
                                </div>
                                <div className='right-part'>
                                    <p>
                                        <span>45</span>
                                        <span>min</span>
                                    </p>
                                    <p>
                                        <span>100</span>
                                        <span>min</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Downloads */}
                    {
                        download && <div className="download-wrapper">
                            <h2>Downloads</h2>
                            <div className="download">
                                <div>
                                    <FontAwesomeIcon className='icon' icon={faFileAlt}></FontAwesomeIcon>
                                    <h3>Syllabus</h3>
                                    <p>Read to know more about the test pattern and syllabus.</p>
                                    <Link to='/'><FontAwesomeIcon icon={faDownload}> Download </FontAwesomeIcon></Link>
                                </div>
                                <div>
                                    <img src={guideline} alt="guideline" />
                                    <h3>Guidelines</h3>
                                    <p>Read to know more about the test pattern and syllabus.</p>
                                    <Link to='/'><FontAwesomeIcon icon={faDownload}> Download </FontAwesomeIcon></Link>
                                </div>
                                <div>
                                    <img src={structure} alt="structure" />
                                    <h3>Structure</h3>
                                    <p>Read to know more about the test pattern and syllabus.</p>
                                    <Link to='/'><FontAwesomeIcon icon={faDownload}> Download </FontAwesomeIcon></Link>
                                </div>
                            </div>
                        </div>
                    }

                    {/* FAQs */}
                    {
                        faq && <div>
                            <h2>FAQs</h2>
                            <div className='faqs'>
                                <div className='question-answer'>
                                    <div className=''>
                                        <div>
                                            {
                                                faqs.map(faq => <Accordion key={faq.id} className='accordion'>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className='text'>{faq.question}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            {faq.answer}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
};

export default UIDesign;