import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './MyCourse.css';
import courseVideo from '../../assets/images/courseVideo.png';
import videoIcon from '../../assets/images/myVideo.png';

const MyCourse = () => {
    return (
        <div className='my-course-wrapper'>
            <div className="inner-my-course">
                {/* Course Video */}
                <div className='course-video'>
                    <h2 className='container'>Lecture2 # Installing Plugins</h2>
                    <img src={courseVideo} alt="courseVideo" />
                    <div className='icon'>
                        <img src={videoIcon} alt="videoIcon" />
                    </div>
                </div>
                {/* Bottom Part */}
                <div className='link-wrapper'>
                    {/* Link Part */}
                    <div className='container my-course-link'>
                        <li>
                            <NavLink to='/description' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Description</NavLink>
                            <NavLink to='/' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Course Content</NavLink>
                            <NavLink to='/reviews' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Reviews</NavLink>
                            <NavLink to='/practice' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Practice</NavLink>
                        </li>
                    </div>

                    {/* Outlet */}
                    <div className='container'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCourse;