import React from 'react';
import './Banner.css';
import banner from '../../../assets/images/banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import buttonVector from '../../../assets/images/banner-vector.png';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className='banner-part'>
            <div className='container'>
                <div className="left-banner">
                    <form>
                        <FontAwesomeIcon className='search' icon={faSearch}></FontAwesomeIcon>
                        <input type="text" disabled name="text" id="text" placeholder='How to get a job in a dream companies' />
                    </form>
                    <h2>Get your dream <span>job</span></h2>
                    <p>Unihire gives opportunities to make you career in your dream industy.STOP Scrolling, keep applying</p>
                    <button type="submit"> <Link to='/jobs'>Get Hired</Link> </button>
                    <img src={buttonVector} alt="vectorImage" />
                </div>
                <div className='right-banner'>
                    <img src={banner} alt="banner" />
                </div>
            </div>
        </div>
    );
};

export default Banner;