import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './JobDisplay.css';

const JobDisplay = () => {

    const menuItems = [
        {
            id: "01",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs"
        },
        {
            id: "02",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs/coming"
        },
        {
            id: "03",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs/coming"
        },
        {
            id: "04",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs/coming"
        },
        {
            id: "05",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs/coming"
        },
        {
            id: "06",
            item: "UI/UX",
            subFirst: "CTC upto 40 LPA",
            subSecond: "100+ Jobs",
            link: "/jobs/coming"
        }
    ];

    return (
        <div className='job-display-wrapper'>
            <div className='container inner-job-display'>
                {/* Link Part */}
                <div className='job-link-part'>
                    <ul>
                        <li>
                            {
                                menuItems.map(menuItem => <NavLink key={menuItem.id} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to={menuItem.link}>
                                    <p className='job-title'> {menuItem.item} </p>
                                    <p className='sub-details'><span>{menuItem.subFirst}</span> <span>{menuItem.subSecond}</span> </p>
                                </NavLink>
                                )
                            }
                        </li>
                    </ul>
                </div>
                {/* Outlet Part */}
                <div>
                    <Outlet> </Outlet>
                </div>
            </div>
        </div>
    );
};

export default JobDisplay;