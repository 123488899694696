import { useEffect, useState } from 'react';

const LoaderTiming = () => {

    // Used for loading
    const [loading, setLoading] = useState(false);

    // Loading functionality and used react sniper loader. You should use loading when fetching data. Its for testing use.
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, [])

    return [loading, setLoading];
};

export default LoaderTiming;