import { Route, Routes } from 'react-router-dom';
import './App.css';
import ComingSoon from './Pages/ComingSoon/ComingSoon';
import Course from './Pages/CoursePage/Course/Course';
// import CourseHome from './Pages/CoursePage/CourseHome/CourseHome';
import Home from './Pages/HomePage/Home/Home';
import Jobs from './Pages/Jobs/Jobs';
import NotFound from './Pages/NotFound/NotFound';
import Footer from './Pages/Shared/Footer/Footer';
import Header from './Pages/Shared/Header/Header';
// import Contact from './Pages/Shared/Contact/Contact';
import JobDashboard from './components/JobDashboard/JobDashboard';
// import ApplyDetails from './Pages/Jobs/ApplyDetails/ApplyDetails';
import { QueryClient, QueryClientProvider } from 'react-query'
// import ApplyDetails from './Pages/Jobs/ApplyDetails/ApplyDetails';
// import ApplicantDetails from './Pages/Jobs/ApplicantsDetails/ApplicantDetails';
import UserProfile from './Pages/UserProfile/UserProfile';
import CourseUI from './components/CourseUI/CourseUI';
import SpecificCourse from './Pages/SpecificCourse/SpecificCourse';
import PaymentPage from './Pages/PaymentPage/PaymentPage';
import CardDetails from './components/CardDetails/CardDetails';
import MyCourse from './Pages/MyCourse/MyCourse';
// import Calendar from './Pages/Jobs/Calendar/Calendar';

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <div className='main'>
        <Header></Header>
        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='home' element={<Home></Home>}></Route>
          <Route path='jobs' element={<Jobs></Jobs>}>
            <Route index element={<JobDashboard></JobDashboard>}></Route>
          </Route>
          {/* <Route path='applyDetails' element={<ApplyDetails></ApplyDetails>}></Route> */}
          {/* <Route path='userProfile' element={<ApplicantDetails></ApplicantDetails>}></Route> */}
          <Route path='course' element={<Course></Course>}>
            <Route index element={<CourseUI></CourseUI>}></Route>
          </Route>
          <Route path='course/:id' element={<SpecificCourse></SpecificCourse>}></Route>
          <Route path='paymentPage' element={<PaymentPage></PaymentPage>}></Route>
          <Route path='cardDetails' element={<CardDetails></CardDetails>}></Route>
          <Route path='coming' element={<ComingSoon></ComingSoon>}></Route>
          <Route path='userProfile' element={<UserProfile>
            <Route index element={<MyCourse></MyCourse>}></Route>
          </UserProfile>}></Route>
          <Route path='*' element={<NotFound></NotFound>}></Route>
        </Routes>
        <Footer></Footer>
      </div>
    </QueryClientProvider>
  );
}

export default App;
