import React from 'react';
import './Slider.css';
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import userImage from '../../../assets/images/userImage.png';
import ayush from '../../../assets/images/ayush.png';
import manish from '../../../assets/images/manish.png';
import kunal from '../../../assets/images/kunal.png';
import rajib from '../../../assets/images/rajib.png';



const Slider = () => {

    return (
        <div className='slider'>
            <h2>Our <span>Team</span> Members</h2>
            <div className='container'>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    centerSlide='true'
                    fade='true'
                    grabCursor='true'
                    autoplay={{
                        delay: 2000,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation, A11y]}
                    className="mySwiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        767: {
                            slidesPerView: 2,
                        },
                        1140: {
                            slidesPerView: 3,
                        }
                    }}
                >

                    <SwiperSlide className='slide slide-second'>
                        <div className='slide-content'>
                            <div className='user-image'>
                                <img src={userImage} alt="userImage" />
                            </div>
                            <h3> Rohit Saran </h3>
                            <p> CEO, Co-Founder </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide slide-third'>
                        <div className='slide-content'>
                            <div className='user-image'>
                                <img src={userImage} alt="userImage" />
                            </div>
                            <h3> Ayush Anand </h3>
                            <p> CTO </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide-content'>
                            <div className='user-image'>
                                <img src={manish} alt="userImage" />
                            </div>
                            <h3> Manish Kumar </h3>
                            <p> Developer </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide slide-first'>
                        <div className='slide-content'>
                            <div className='user-image'>
                                <img src={rajib} alt="userImage" />
                            </div>
                            <h3> Md. Rajebul Alam </h3>
                            <p> Developer </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide slide-first'>
                        <div className='slide-content'>
                            <div className='user-image'>
                                <img src={kunal} alt="userImage" />
                            </div>
                            <h3> Kunal Raj </h3>
                            <p> Developer </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
};

export default Slider;