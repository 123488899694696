import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './ErrorNotification.css';

const ErrorNotification = () => {

    const [errorNotification, setErrorNotification] = useState();
    useEffect(() => {
        setErrorNotification(
            <div className='error-notify'>
                <div className='inner-notify'>
                    <div className='left'>
                        <FontAwesomeIcon className='icon' icon={faXmark}></FontAwesomeIcon>
                    </div>
                    <div>
                        <h3>Uh Oh ! There is an Error</h3>
                        <p>Retry and make sure, All the entered field is correct</p>
                    </div>
                </div>
            </div>
        )
    }, [])

    return [errorNotification, setErrorNotification];
};

export default ErrorNotification;