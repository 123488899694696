import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './SuccessNotify.css';

const SuccessNotify = () => {

    const [successNotification, setSuccessNotification] = useState();
    useEffect(() => {
        setSuccessNotification(
            <div className='success-notify'>
                <div className='inner-notify'>
                    <div className='left'>
                        <FontAwesomeIcon className='icon' icon={faCheck}></FontAwesomeIcon>
                    </div>
                    <div>
                        <h3>Nice Job ! You have successfully applied for this job role. </h3>
                        <p>Wait for the employer message to start the process. </p>
                    </div>
                </div>
            </div>
        )
    }, [])

    return [successNotification, setSuccessNotification];
};

export default SuccessNotify;