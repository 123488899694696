import React from 'react';
import './CardDetails.css';
import { faArrowRotateBack, faCreditCardAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import courseImage from '../../assets/images/topCourse.png';
import { Link } from 'react-router-dom';

const CardDetails = () => {
    return (
        <div className='payment-wrapper'>
            <div className="container card-details">
                <Link to='/paymentPage'>
                    <FontAwesomeIcon className='icon' icon={faArrowRotateBack}></FontAwesomeIcon>
                    Back
                </Link>
                <div className='inner-payment'>
                    <div className='left'>
                        <div className='inner-left'>
                            <p> <FontAwesomeIcon className='icon' icon={faCreditCardAlt} ></FontAwesomeIcon> Credit / Debit Card</p>
                            <form className='card-form'>
                                <div>
                                    <label htmlFor="cardName">Name on Card</label>
                                    <input type="text" name="cardName" id="cardName" placeholder='Unihire' />
                                </div>
                                <div>
                                    <label htmlFor="cardNo">Card No.</label>
                                    <input type="text" name="cardNo" id="cardNo" placeholder='000 000 000 000 000 0000' />
                                </div>
                                <div className='date-cvc'>
                                    <div>
                                        <label htmlFor="date">Date of Expire</label>
                                        <input type="date" name="date" id="cardNo" />
                                    </div>
                                    <div>
                                        <label htmlFor="cvc">CVV/CVC</label>
                                        <input type="text" name="cvc" id="cvc" placeholder='XXX' />
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* Items */}
                        <div className='items-wrapper'>
                            <h2>Items:</h2>
                            <div className='items'>
                                <div>
                                    <img src={courseImage} alt="itemImage" />
                                    <h3>The UI/UX Bootcamp: Zero to Hero</h3>
                                </div>
                                <h4>3499</h4>
                            </div>
                        </div>
                    </div>
                    {/* Right Part */}
                    <div className='right'>
                        <h2>Summary</h2>
                        <div className='course-fee'>
                            <span>Course Fee:</span>
                            <span>3499</span>
                        </div>
                        <div className='total'>
                            <span>Total:</span>
                            <span>3499</span>
                        </div>
                        <p>By completing your purchase you agree to these <span>Terms of Service</span> </p>
                        <button type="submit">Complete Checkout</button>
                        <p>15 Days money back guarantee</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default CardDetails;